/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}

.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 48%;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  margin-top: -10px\9; /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slider-nav01 .slick-prev,
.slider-nav01 .slick-next,
.slider-nav02 .slick-prev,
.slider-nav02 .slick-next {
  top: 35%;
  width: 18px;
  height: 42px;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: transparent;
  outline: none;
  /*background: transparent;*/
  opacity: 0.8;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: 10%;
  background: url(../img/cmn/prev.png) no-repeat;
  background-size: 56px 56px;
  z-index: 100;
}
[dir='rtl'] .slick-prev {
  right: 10px;
  left: auto;
}
.slick-next {
  right: 10%;
  background: url(../img/cmn/next.png) no-repeat;
  background-size: 56px 56px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: 10px;
}
.section_reason .slick-prev {
  left: -70px;
  top: 44%;
  background: url(../img/cmn/prev_s.png) no-repeat;
  background-size: 31px 42px;
  width: 31px;
  height: 42px;
}
.section_reason .slick-next {
  right: -70px;
  top: 44%;
  background: url(../img/cmn/next_s.png) no-repeat;
  background-size: 31px 42px;
  width: 31px;
  height: 42px;
}
